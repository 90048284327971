import React, { useState } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoBox
} from '@react-google-maps/api';
import { useAssets } from 'expo-asset';
import { View, Text } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

// @ts-ignore
// https://react-google-maps-api-docs.netlify.app/#section-getting-started
export const Map = (props: any) => {
  const { markers, brand } = props;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCtLCXz_c3wFJL-9gNq3atNiBpm7aOnJqQ'
  });
  const [assets, error] = useAssets([require('../assets/images/mappin.png')]);
  const [openMarker, setOpenMarker] = useState('');

  const [_, setMap] = React.useState(null);

  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map.fitBounds(bounds);
  //   setMap(map);
  // }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  console.log(google.maps);

  return (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '100%'
      }}
      options={{
        styles: mapStyle,
        disableDefaultUI: true,
        panControl: true,
        zoomControl: true,
        scaleControl: true,
        maxZoom: 16,
        zoom: 12,
        center: {
          lng: -86.76796,
          lat: 36.174465
        }
      }}
      // onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {!!markers && markers.length > 0 ? (
        markers?.map((marker: any) => (
          <Marker
            position={{
              lng: marker.longitude,
              lat: marker.latitude
            }}
            key={`${marker.longitude}${marker.latitude}`}
            title={brand?.name}
            onClick={() =>
              setOpenMarker(`${marker.longitude}${marker.latitude}`)
            }
            options={{
              // @ts-ignore
              icon: assets?.[0]['localUri'] || ''
            }}
          >
            {openMarker === `${marker.longitude}${marker.latitude}` && (
              <InfoBox
                position={
                  new google.maps.LatLng(marker.latitude, marker.longitude)
                }
                options={{
                  enableEventPropagation: true,
                  closeBoxURL: ''
                }}
              >
                <View
                  style={{
                    width: 200,
                    padding: 10,
                    backgroundColor: '#ffffff',
                    borderRadius: 6
                  }}
                >
                  <View
                    style={{
                      position: 'absolute',
                      right: 4,
                      top: 4
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        setOpenMarker('');
                      }}
                    >
                      <Text>X</Text>
                    </TouchableOpacity>
                  </View>
                  <Text>{brand?.name || 'Restaurant'}</Text>
                </View>
              </InfoBox>
            )}
          </Marker>
        ))
      ) : (
        <></>
      )}
    </GoogleMap>
  );
};

// https://snazzymaps.com/style/286365/k-i-s-s-white
export const mapStyle = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#444444'
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100
      },
      {
        lightness: 45
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified'
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#cbcbcb'
      },
      {
        visibility: 'on'
      }
    ]
  }
];
