import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useFetch, HTTPmethod } from './useFetch';

// id: string
// slug: string
// name: string
// created: string
// colors?: string[]
// businessAddress?: string

export const useBrands = () => {
  const getData = useFetch(`/admin/brand/list`);
  return useQuery('brands', async () => {
    return await getData();
  });
};

// todo only slug is working // no id get yet
export const useBrand = (id?: string) => {
  const getData = useFetch(`/admin/brand/${id}`);
  return useQuery(`brand:${id}`, async () => {
    return await getData(), { enabled: id === 'new' };
  });
};

export const useMutateBrand = (id?: string) => {
  const mutateBrand = !id
    ? useFetch('/admin/brand', HTTPmethod.PUT)
    : useFetch(`/admin/brand/${id}`, HTTPmethod.POST);

  const queryClient = useQueryClient();

  return useMutation(
    (brand: any) => {
      return mutateBrand(brand);
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(['brands', `brand:${id}`]);
      }
    }
  );
};
