import React from 'react';
import { View, Image, useWindowDimensions } from 'react-native';
import { usePosts } from '../hooks/usePosts';
import { Text, useThemeColor } from '../components/Themed';
import { Map } from '../components/Map.web';
import { useAssets } from 'expo-asset';
import { NavBar } from '../components/NavBar';
import { HeaderText } from '../components/Text';

export const PostedUpAt = (props: any) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 640;
  const slug = props.route.params.slug;
  const { data } = usePosts(slug);
  // todo: when active is coming down show marekrs
  // const markers = data?.active?.flatMap((post: any) => {
  //   return {
  //     latitude: post.lat,
  //     longitude: post.long
  //   };
  // })
  const markers = [
    {
      longitude: -86.76796,
      latitude: 36.174465
    }
  ];

  console.log(markers);

  const [assets, error] = useAssets([
    require('../assets/images/stock3.jpg'),
    require('../assets/images/full mark white@2x-8.png'),
    require('../assets/images/logo white@2x-8.png'),
    require('../assets/images/logo black@2x-8.png')
  ]);
  const textColor = useThemeColor(
    { light: '#000000', dark: '#ffffff' },
    'text'
  );
  const borderColor = textColor;

  return (
    <>
      <NavBar {...props} />
      <View
        style={{
          justifyContent: 'center',
          alignItems: isMobile ? 'flex-start' : 'center',
          flexDirection: 'row',
          minHeight: isMobile ? undefined : '100vh'
        }}
      >
        <View
          style={{
            maxWidth: 1600,
            width: '100%',
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          <View
            style={{
              maxWidth: 800,
              flex: 1,
              minWidth: isMobile ? 200 : 400,
              maxHeight: 800
            }}
          >
            <View
              style={{
                flex: 1,
                minHeight: isMobile ? 200 : 400,
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: 1,
                borderColor
              }}
            >
              <Image
                style={{
                  padding: 20,
                  width: '100%',
                  height: isMobile ? 100 : 300
                }}
                resizeMode={'contain'}
                source={{
                  uri: 'https://images.squarespace-cdn.com/content/v1/623b580ec766653f7d783712/73e935c3-39f4-4a83-81d4-eb100999a58d/diceys-circlelogo.png'
                  // assets?.[1]['localUri'] || ''
                }}
              />
              <Text
                style={{
                  paddingTop: 8,
                  fontSize: isMobile ? 36 : 56,
                  fontWeight: '500'
                }}
              >
                {data?.brand?.name}
              </Text>
            </View>
            {isMobile && (
              <View style={{ height: 400, width: '100%' }}>
                <View
                  style={{
                    flex: 1,
                    maxWidth: 800,
                    backgroundColor: '#ffffff',
                    minWidth: 320,
                    height: 500
                  }}
                >
                  <Map markers={markers} brand={data?.brand} />
                </View>
              </View>
            )}
            {!isMobile && (
              <View
                style={{
                  flexDirection: 'row',
                  flex: 1,
                  borderWidth: 1,
                  borderColor,
                  flexWrap: 'wrap'
                }}
              >
                <View style={{ width: '50%', padding: 20 }}>
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: '500',
                      paddingBottom: 20
                    }}
                  >
                    About us
                  </Text>
                  <Text style={{ fontSize: isMobile ? 16 : 18 }}>
                    Our NY style dough is made from scratch daily, aged
                    in-house, hand-tossed, and topped with our hand-crushed plum
                    tomato sauce and houseshredded mozzarella (unless otherwise
                    indicated). We bake each pizza to order on 2” bricks, in
                    old-school gas-fired deck ovens.
                  </Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderLeftWidth: 2,
                    borderColor
                  }}
                >
                  <Image
                    style={{
                      width: '100%',
                      height: '100%',
                      maxHeight: 400
                    }}
                    source={{
                      uri: assets?.[0]['localUri'] || ''
                    }}
                  />
                </View>
              </View>
            )}
          </View>

          {!isMobile && (
            <View
              style={{
                flex: 1,
                maxWidth: 800,
                backgroundColor: '#ffffff',
                borderWidth: 1,
                minWidth: 320,
                borderColor,
                height: '100vh'
              }}
            >
              <Map markers={markers} brand={data?.brand} />
            </View>
          )}
        </View>
      </View>

      {isMobile && (
        <View
          style={{
            borderWidth: 1,
            borderColor,
            width: '100%'
          }}
        >
          <View style={{ padding: 20, minHeight: 250 }}>
            <Text
              style={{ fontSize: 20, fontWeight: '500', paddingBottom: 20 }}
            >
              About us
            </Text>
            <Text>
              Our NY style dough is made from scratch daily, aged in-house,
              hand-tossed, and topped with our hand-crushed plum tomato sauce
              and houseshredded mozzarella (unless otherwise indicated). We bake
              each pizza to order on 2” bricks, in old-school gas-fired deck
              ovens.
            </Text>
          </View>

          <Image
            style={{
              width: '100%',
              height: '100%',
              maxHeight: 400
            }}
            source={{
              uri: assets?.[0]['localUri'] || ''
            }}
          />
        </View>
      )}
    </>
  );
};
