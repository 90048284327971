/**
 * If you are not familiar with React Navigation, check out the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { ColorSchemeName } from 'react-native';
import { LoginScreen } from '../screens/LoginScreen';

import NotFoundScreen from '../screens/NotFoundScreen';
import { PostedUpAt } from '../screens/PostedUpAt';
import { SignUpScreen } from '../screens/SignUpScreen';
import { RootStackParamList } from '../types';
import BottomTabNavigator from './BottomTabNavigator';
import LinkingConfiguration from './LinkingConfiguration';
import { useUser } from '../hooks/useUser';
import { BrandDetailScreen } from '../screens/BrandDetailScreen';
import { CartsScreen } from '../screens/CartsScreen';

const PostedUpTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    pink: '#fef2f4',
    green: '#f0fdf6',
    blue: '#ebf2fc',
    background: '#ffffff'
  }
};

export default function Navigation({
  colorScheme
}: {
  colorScheme: ColorSchemeName;
}) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : PostedUpTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator() {
  const { signedIn } = useUser();

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      {
        // @ts-ignore
        signedIn ? (
          <Stack.Screen name="Root" component={BottomTabNavigator} />
        ) : (
          <Stack.Screen name="Root" component={SignUpScreen} />
        )
      }
      <Stack.Screen
        name="brands"
        component={BrandDetailScreen}
        options={{ title: 'Brand' }}
      />
      <Stack.Screen
        name="vendors"
        component={CartsScreen}
        options={{ title: 'Locations' }}
      />
      <Stack.Screen
        name="signup"
        component={SignUpScreen}
        options={{ title: 'Sign Up' }}
      />
      <Stack.Screen
        // @ts-ignore
        name="with"
        component={PostedUpAt}
        options={{ title: 'Posted Up!' }}
      />
      <Stack.Screen
        name="login"
        component={LoginScreen}
        options={{ title: 'Login | Posted Up' }}
      />
      {
        // @ts-ignore
        signedIn ? (
          <Stack.Screen
            name="home"
            component={BottomTabNavigator}
            options={{ title: 'Oops!' }}
          />
        ) : (
          <Stack.Screen
            name="NotFound"
            component={NotFoundScreen}
            options={{ title: 'Oops!' }}
          />
        )
      }
    </Stack.Navigator>
  );
}
