import React, { useState, useEffect } from 'react';
import { StyleSheet } from 'react-native';
// import { PUButton } from '../components/Button';

import {
  // Text,
  View
} from '../components/Themed';
// import * as Location from 'expo-location';
import { BrandsList } from '../components/BrandsList';

export default function TabOneScreen(props: any) {
  // const [location, setLocation] = useState(null);
  // const [errorMsg, setErrorMsg] = useState('');
  // const [selectedTruck, setSelectedTruck] = useState('');

  return (
    <View style={styles.container}>
      {/* todo for live locations */}
      {/* 
      <Text style={styles.title}>
        Select Your Truck/Cart/Popup and broadcast your location!
      </Text>

      <View
        style={styles.separator}
        lightColor="#eee"
        darkColor="rgba(255,255,255,0.1)"
      />
      {!!errorMsg && <Text>{errorMsg}</Text>}
      <PUButton
        onPress={async () => {
          const { status } = await Location.requestForegroundPermissionsAsync();
          if (status !== 'granted') {
            setErrorMsg('Permission to access location was denied');
            return;
          }

          let location = await Location.getCurrentPositionAsync({});
          // @ts-ignore
          setLocation(location);
        }}
        title={'Set Location'}
      /> */}
      <BrandsList {...props} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%'
  }
});
