import { useUser } from './useUser';
import { Platform } from 'react-native';

const local = Platform.OS === 'web' && window.location.hostname === 'localhost';

const baseUrl = local
  ? 'http://localhost:3000/api'
  : 'https://dn36jmwp7z5rmn4g6flcckj2jm0vmxlm.lambda-url.us-east-1.on.aws/api';

export enum HTTPmethod {
  PUT = 'put',
  GET = 'get',
  POST = 'post',
  DELETE = 'delete'
}

export const useFetch = (path: string, method?: HTTPmethod) => {
  const { auth } = useUser();
  const fetcher = async (body?: any) => {
    const response = await fetch(`${baseUrl}${path}`, {
      method: method || HTTPmethod.GET,
      headers: new Headers({
        Authorization: auth,
        'Content-Type': 'application/json'
      }),
      body: body
        ? JSON.stringify({
            ...body
          })
        : undefined
    });
    return response.json();
  };
  return fetcher;
};
