import React from 'react';
import { useThemeColor, View } from './Themed';
import { Image, useWindowDimensions } from 'react-native';
import { PUButton } from './Button';
import { useLogo } from '../hooks/useLogo';

export const NavBar = (props: any) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 640;

  const textColor = useThemeColor(
    { light: '#000000', dark: '#ffffff' },
    'text'
  );
  const borderColor = textColor;
  const logoUri = useLogo();

  return (
    <View
      // @ts-ignore hack for making web nav bar fixed for web
      style={{
        position: 'relative',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row'
      }}
    >
      <View
        style={{
          minHeight: 50,
          width: '100%',
          maxWidth: 1600,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderTopWidth: 0,
          justifyContent: 'space-between',
          flexDirection: 'row',
          borderBottomWidth: 1,
          borderColor
        }}
      >
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: 10,
            width: 130
          }}
        >
          <Image
            style={{
              padding: 5,
              width: 100,
              height: 20
            }}
            resizeMode={'contain'}
            source={{
              uri: logoUri
            }}
          />
        </View>

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingRight: 10,
            width: isMobile ? 100 : 320
          }}
        >
          <PUButton
            colors={['#0029FF', '#FF00E5']}
            buttonHeight={isMobile ? 30 : 40}
            title={'Login'}
            style={{
              marginRight: isMobile ? 5 : 10
            }}
            fontSize={isMobile ? 12 : 18}
            minWidth={isMobile ? 70 : 100}
            onPress={() => {
              props.navigation.replace('login');
            }}
          ></PUButton>
          <PUButton
            title={'Signup'}
            buttonHeight={isMobile ? 30 : 40}
            fontSize={isMobile ? 12 : 18}
            minWidth={isMobile ? 70 : 100}
            onPress={() => {
              props.navigation.replace('signup');
            }}
          ></PUButton>
        </View>
      </View>
    </View>
  );
};
