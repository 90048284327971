import { useAssets } from 'expo-asset';
import React from 'react';
import { Image, View } from 'react-native';
import { useLogo } from '../hooks/useLogo';

export const LogoBrandName = () => {
  const [assets, error] = useAssets([
    require('../assets/images/ice cream.png')
  ]);
  const logoUri = useLogo();
  return (
    <View
      style={{
        width: 400,
        height: 100,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
      }}
    >
      <Image
        style={{
          alignSelf: 'flex-start',
          width: 100,
          height: 100,
          marginTop: 30,
          marginLeft: 50,
          marginRight: -50
        }}
        source={{ uri: assets?.[0]['localUri']! || '' }}
        resizeMode="contain"
      ></Image>
      <Image
        style={{
          marginLeft: -50,
          alignSelf: 'flex-end',
          width: 400,
          height: 40
        }}
        source={{ uri: logoUri }}
        resizeMode="contain"
      ></Image>
    </View>
  );
};
