import React, { useState, useEffect } from 'react';
import { View, Image } from 'react-native';
import { PUButton } from '../components/Button';
import { PUTextInput } from '../components/PUTextInput';
import { Text } from '../components/Themed';
import { LogoBrandName } from '../components/LogoBrandName';
import { StackScreenProps } from '@react-navigation/stack/lib/typescript/src/types';
import { RootStackParamList } from '../types';
import { Auth } from 'aws-amplify';
import { useUser } from '../hooks/useUser';
import { useLogo } from '../hooks/useLogo';

export const SignUpScreen = ({
  navigation
}: StackScreenProps<RootStackParamList, 'signup'>) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [brandName, setBrandName] = useState('postedup.io/');
  const [errorMessage, setErrorMessage] = useState('');
  const { setSignedIn } = useUser();

  return (
    <View
      style={{
        padding: 20,
        minWidth: '100%',
        minHeight: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <View style={{ maxWidth: 400, width: '100%' }}>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LogoBrandName />
        </View>
        <View
          style={{
            padding: 20,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Text style={{ fontSize: 32 }}>{'Sign up!'}</Text>
        </View>

        <PUTextInput
          onChangeText={setEmail}
          placeholder={'Email'}
          value={email}
        ></PUTextInput>
        <View style={{ padding: 8 }} />
        <PUTextInput
          onChangeText={setName}
          placeholder={'Name'}
          value={name}
        ></PUTextInput>
        <View style={{ padding: 8 }} />

        <PUTextInput
          onChangeText={setPassword}
          placeholder={'Password'}
          secureTextEntry={true}
          value={password}
        ></PUTextInput>
        <View style={{ padding: 8 }} />
        <PUTextInput
          onChangeText={setConfirmPassword}
          placeholder={'Confirm Password'}
          secureTextEntry={true}
          value={confirmPassword}
        ></PUTextInput>
        <View style={{ padding: 8 }} />
        <View
          style={{
            width: '85%'
          }}
        >
          <PUTextInput
            onChangeText={(text: string) =>
              setBrandName(
                'postedup.io/' +
                  text
                    .replace('postedup.io/', '')
                    .replace(/\s+/g, '-')
                    .toLowerCase()
              )
            }
            placeholder={`postedup.io/your-brand-name`}
            value={brandName === 'postedup.io/' ? '' : brandName}
          />
        </View>
        <View style={{ padding: 8 }}></View>
        <View style={{ padding: 8 }}></View>
        {!!errorMessage && (
          <>
            <Text style={{ color: '#ff0000' }}>{errorMessage}</Text>{' '}
            <View style={{ padding: 8 }}></View>
          </>
        )}
        <PUButton
          onPress={async () => {
            if (!email) {
              setErrorMessage('Please enter your email');
              return;
            }
            if (!password) {
              setErrorMessage('Please enter a password');
              return;
            }
            if (password !== confirmPassword) {
              setErrorMessage('Passwords must match');
              return;
            }
            await Auth.signUp({
              username: email,
              password
              // attributes: {
              //   name
              // }
            });
            try {
              const user = await Auth.signIn(email, password);
              setSignedIn(
                !!user,
                user.signInUserSession.accessToken.jwtToken,
                user.signInUserSession.accessToken.payload.username
              );
              // @ts-ignore
              navigation.replace('Root');
            } catch {
              setSignedIn(false, '', '');
            }
          }}
          title={'Sign Up'}
        />
        <Text>Already have an account?</Text>
        <PUButton
          onPress={() => {
            navigation.replace('login');
          }}
          title={'Go Login'}
        />
      </View>
    </View>
  );
};
