// MAKE A NEW BRAND, list all brands

import { invertColor, View } from './Themed';
import { TouchableOpacity } from 'react-native';
import { StackActions } from '@react-navigation/native';
import { PUButton } from './Button';
import { useBrands } from '../hooks/useBrands';
import { Text } from './Themed';
interface BrandType {
  id: string;
  name: string;
  slug: string;
  colors?: Array<string>;
  cartCount: number;
}

interface BrandCardProps extends BrandType {
  navigation: any;
}

export const BrandsList = (props: any) => {
  const { data } = useBrands();
  const brands = data || [];

  return (
    <>
      <PUButton
        onPress={() => {
          props.navigation.dispatch(
            StackActions.push(`brands`, { id: 'new', slug: 'new' })
          );
        }}
        style={{ width: 200, marginBottom: 10, maxWidth: 200 }}
        title={'New Brand +'}
      />
      {brands.map(
        (brand: any) =>
          !!brand && (
            <BrandCard
              {...brand}
              key={brand.id}
              navigation={props.navigation}
            />
          )
      )}
    </>
  );
};

const BrandCard = (props: BrandCardProps) => {
  const color = props?.colors?.[0] || '#ff00ff';
  const secondaryColor = props?.colors?.[1] || '#ffffff';

  return (
    <TouchableOpacity
      onPress={() => {
        props.navigation.dispatch(
          StackActions.push(`brands`, { slug: props.slug, id: props.id })
        );
      }}
    >
      <View
        style={{
          backgroundColor: color,
          borderRadius: 6,
          width: 200,
          height: 50,
          alignItems: 'center',
          padding: 20,
          paddingTop: 10,
          marginBottom: 10,
          borderColor: secondaryColor,
          borderWidth: 1
        }}
      >
        <Text
          style={{
            color: invertColor(color)
          }}
        >
          {props.name}
        </Text>
      </View>
    </TouchableOpacity>
  );
};
