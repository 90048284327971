import { StackScreenProps } from '@react-navigation/stack/lib/typescript/src/types';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { PUButton } from '../components/Button';
import { PUTextInput } from '../components/PUTextInput';
import { Text } from '../components/Themed';
import { useUser } from '../hooks/useUser';
import { RootStackParamList } from '../types';
import { Image, View } from 'react-native';
import { useLogo } from '../hooks/useLogo';
import { useTheme } from '@react-navigation/native';
import { LogoBrandName } from '../components/LogoBrandName';
export const LoginScreen = ({
  navigation
}: StackScreenProps<RootStackParamList, 'login'>) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { setSignedIn, auth } = useUser();
  const { colors } = useTheme();
  const logoUri = useLogo();
  useEffect(() => {
    if (auth) {
      navigation.replace('Root');
    }
  }, [auth]);

  return (
    <View
      style={{
        display: 'flex',
        minWidth: '100%',
        minHeight: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white'
      }}
    >
      <View
        style={{
          maxWidth: 400,
          width: 400,
          // backgroundColor: colors.blue,
          padding: 50
        }}
      >
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 50,
            marginLeft: 10
          }}
        >
          <LogoBrandName />
        </View>
        <PUTextInput
          onChangeText={setEmail}
          placeholder={'Email'}
          value={email}
        ></PUTextInput>
        <View style={{ padding: 4 }} />
        <PUTextInput
          onChangeText={setPassword}
          placeholder={'Password'}
          secureTextEntry={true}
          value={password}
        ></PUTextInput>
        <View style={{ padding: 8 }}></View>
        {!!errorMessage && (
          <>
            <Text>{errorMessage}</Text> <View style={{ padding: 8 }}></View>
          </>
        )}
        <PUButton
          onPress={async () => {
            try {
              const user = await Auth.signIn(email, password);
              if (user) {
                setSignedIn(
                  !!user,
                  user.signInUserSession.accessToken.jwtToken,
                  user.signInUserSession.accessToken.payload.username
                );
                navigation.replace('Root');
              }
            } catch (error) {
              setErrorMessage(`${error}`);
            }
          }}
          title={'SIGN IN'}
          disabled={!email || !password}
        />
        <PUButton
          onPress={() => {
            navigation.replace('signup');
          }}
          title={'Go to Signup'}
        />
      </View>
    </View>
  );
};

// const { user, error } = await auth.signIn({
//   email: 'example@email.com',
//   password: 'example-password',
// })

// Users - a vendor can have multiple users with access to multiple carts
// Vendors - large brand like Five Points Pizza
// Carts - trucks or carts underneath a Vendor like Five Points Pizza Truck #1 (pdf?)
// Locations - each cart has a lat, long, start time, end time and active indicator (keep record of all locations, only 1 active location at a time per cart)
