import { useAssets } from 'expo-asset';
import { useThemeColor } from '../components/Themed';

// supports light and dark mode
export const useLogo = () => {
  const [assets, error] = useAssets([
    require('../assets/images/logo white@2x-8.png'),
    require('../assets/images/logo black@2x-8.png')
  ]);
  const textColor = useThemeColor(
    { light: '#000000', dark: '#ffffff' },
    'text'
  );
  // return textColor === '#ffffff'
  //   ? assets?.[0]['localUri'] || ''
  return assets?.[1]['localUri'] || '';
};
