import React from 'react';
import { TextInput, TextInputProps, View } from 'react-native';
// @ts-ignore

export const PUTextInput = (props: TextInputProps | any) => (
  <TextInput
    {...props}
    style={{
      backgroundColor: '#ffffff',
      placeholderTextColor: 'grey',
      fontWeight: 800,
      padding: 12,
      borderRadius: 0,
      borderWidth: 2,
      borderColor: '#000000',
      // TODO make work in dark mode
      borderBottomColor: '#000000',
      ...props?.style
    }}
  />
);
