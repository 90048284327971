import { Auth } from 'aws-amplify';
import * as React from 'react';
import { StyleSheet } from 'react-native';
import { PUButton } from '../components/Button';

import EditScreenInfo from '../components/EditScreenInfo';
import { Text, View } from '../components/Themed';
import { useUser } from '../hooks/useUser';

export default function TabTwoScreen(props: any) {
  const { setSignedIn } = useUser();
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Settings</Text>
      <PUButton
        onPress={async () => {
          await Auth.signOut();
          setSignedIn(false, '', '');
          props.navigation.replace('Root');
        }}
        title={'Sign Out'}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%'
  }
});
