import { useEffect, useState } from 'react';
import { PUButton } from '../components/Button';
import { PUTextInput } from '../components/PUTextInput';
import { Text } from '../components/Themed';
import { useMutateBrand, useBrand } from '../hooks/useBrands';
import { ActivityIndicator, View } from 'react-native';
import { ColorPicker } from '../components/ColorPicker';
import { useUser } from '../hooks/useUser';
import { StackActions } from '@react-navigation/native';

export const BrandDetailScreen = (props: any) => {
  const id = props.route.params.id;
  const paramSlug = props.route.params.slug;
  const isNew = props.route.params.id === 'new';
  const { data, isError, refetch, isLoading } = useBrand(id);
  const { mutate } = useMutateBrand(id);
  const { auth } = useUser();

  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [color, setColor] = useState('#ff00ff');
  const [secondaryColor, setSecondaryColor] = useState('#ffffff');

  const [businessAddress, setBusinessAddress] = useState('');

  useEffect(() => {
    if (data?.name) {
      setName(data?.name);
      setSlug(data?.slug);
      setColor(data?.colors?.[0]);
      setSecondaryColor(data?.colors?.[1]);
      setBusinessAddress(data?.businessAddress);
    }
    if (isNew) {
      setName('');
      setSlug('');
      setColor('#ff00ff');
      setSecondaryColor('#ffffff');
      setBusinessAddress('');
    }
  }, [data?.name]);

  useEffect(() => {
    if (isError && auth) {
      refetch();
    }
  }, [isError, auth]);

  if (isError || isLoading) {
    return (
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          height: 400
        }}
      >
        <ActivityIndicator size="large" color="#ff00ff" />
      </View>
    );
  }

  return (
    <View
      style={{
        alignItems: 'center'
      }}
    >
      <View
        style={{
          width: 290
        }}
      >
        <View style={{ marginTop: 20, marginBottom: 20 }}>
          {!isNew && (
            <Text
              style={{
                fontSize: 36
              }}
            >
              {'Edit your Brand'}
            </Text>
          )}
          {isNew && (
            <Text
              style={{
                fontSize: 36
              }}
            >
              {'Create Your Brand'}
            </Text>
          )}
        </View>
        <PUTextInput
          onChangeText={setName}
          placeholder={'Name'}
          value={name}
          style={{ marginBottom: 10 }}
        ></PUTextInput>
        <View
          style={{
            flexDirection: 'row'
          }}
        >
          <Text style={{ fontSize: 16 }}>{`https://postedup.io/`}</Text>
          <PUTextInput
            onChangeText={setSlug}
            placeholder={'your-branded-url'}
            value={slug}
            style={{ marginBottom: 10, padding: 4, width: 150 }}
          ></PUTextInput>
        </View>
        <ColorPicker color={color} setColor={setColor} />
        <ColorPicker
          color={secondaryColor}
          setColor={setSecondaryColor}
          placeholder={'#FFFFFF (secondary color)'}
        />
        <PUTextInput
          onChangeText={setBusinessAddress}
          placeholder={'Business Address'}
          value={businessAddress}
          style={{ marginBottom: 10 }}
        ></PUTextInput>
        <PUButton
          title={'Save'}
          style={{
            minWidth: 290,
            marginBottom: 10
          }}
          onPress={async () => {
            await mutate({
              id: isNew ? undefined : data?.id,
              name,
              slug,
              colors: [color, secondaryColor],
              businessAddress
            });
            // only navigate to a new page if we have an id already
            if (!isNew) {
              props.navigation.replace('Root');
            }
          }}
        />
        {!isNew && (
          <PUButton
            title={'Add Vendor +'}
            style={{
              minWidth: 290
            }}
            onPress={() => {
              props.navigation.dispatch(
                StackActions.push(`vendors`, { slug: data?.slug, id: data?.id })
              );
            }}
          />
        )}
      </View>
    </View>
  );
};
