import { Auth } from 'aws-amplify';
import { useEffect, useState, useContext, createContext } from 'react';

const UserContext = createContext({
  state: {
    signedIn: false,
    userId: '',
    auth: ''
  },
  setState: (state: any) => {}
});

const Provider = UserContext.Provider;

export const UserProvider = (props: any) => {
  const [state, setState] = useState({
    signedIn: false,
    userId: '',
    auth: ''
  });

  return <Provider value={{ state, setState }}>{props.children}</Provider>;
};

export const useUser = () => {
  const { state, setState } = useContext(UserContext);

  const setSignedIn = (signedIn: boolean, auth?: string, userId?: string) => {
    setState({
      ...state,
      auth,
      userId,
      signedIn
    });
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setSignedIn(
        !!user,
        user.signInUserSession.accessToken.jwtToken,
        user.signInUserSession.accessToken.payload.username
      );
    });
  }, []);

  return { ...state, setSignedIn };
};
