import 'react-native-gesture-handler';
import { StatusBar } from 'expo-status-bar';
import { useFonts } from 'expo-font';
import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';

import { ReactQueryDevtools } from 'react-query/devtools';
import { Platform } from 'react-native';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './queryClient';

import { Amplify } from 'aws-amplify';
import { UserProvider } from './hooks/useUser';

Amplify.configure({
  // dev
  aws_cognito_region: 'us-east-1', // (required) - Region where Amazon Cognito project was created
  aws_user_pools_id: 'us-east-1_J5lc1RrUP', // (optional) -  Amazon Cognito User Pool ID
  aws_user_pools_web_client_id: '354h7hvj5girnniiasqarc59le' // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
});

export default function App() {
  const [fontsLoaded] = useFonts({
    'Agrandir-Variable': require('./assets/fonts/Agrandir-Variable.ttf')
  });
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  if (!isLoadingComplete || !fontsLoaded) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <UserProvider>
          <QueryClientProvider client={queryClient}>
            <Navigation colorScheme={'light'} />
            <StatusBar />
            {Platform.OS === 'web' && <ReactQueryDevtools initialIsOpen />}
          </QueryClientProvider>
        </UserProvider>
      </SafeAreaProvider>
    );
  }
}
