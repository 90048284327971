import React from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { TouchableOpacity } from 'react-native';
import { Text, useThemeColor } from './Themed';

interface PUButtonProps {
  onPress: (args: any | undefined) => any | undefined;
  title?: string;
  loading?: boolean;
  disabled?: boolean;
  style?: any;
  colors?: Array<string>;
  minWidth?: any;
  fontSize?: any;
  buttonHeight?: any;
}

export const PUButton = (props: PUButtonProps) => {
  // TODO brand color on buttons
  const textColor = useThemeColor(
    { light: '#ffffff', dark: '#000000' },
    'text'
  );
  const backgroundColor = useThemeColor(
    { light: '#000000', dark: '#ffffff' },
    'background'
  );
  return (
    <TouchableOpacity
      onPress={props.onPress}
      disabled={props.disabled}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 500,
        height: 50,
        ...props?.style
      }}
    >
      <LinearGradient
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        colors={
          props?.disabled
            ? ['#dddddd', '#dddddd']
            : [backgroundColor, backgroundColor]
        }
        style={{
          padding: 12,
          alignItems: 'center',
          justifyContent: 'center',
          height: props?.buttonHeight || 40,
          borderRadius: 6,
          minWidth: props.minWidth || 200,
          width: '100%'
        }}
      >
        <Text
          style={{
            fontSize: props?.fontSize || 18,
            color: textColor
          }}
        >
          {props.title}
        </Text>
      </LinearGradient>
    </TouchableOpacity>
  );
};
