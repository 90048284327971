import React from 'react';
import { View } from 'react-native';
import { PUTextInput } from './PUTextInput';

export const ColorPicker = (props: {
  color: string;
  setColor: any;
  placeholder?: string;
}) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        marginBottom: 10
      }}
    >
      <View
        style={{
          backgroundColor: props.color,
          borderRadius: 6,
          width: 40,
          height: 40,
          marginRight: 5
        }}
      ></View>
      <PUTextInput
        onChangeText={(c: string) =>
          c.length > 7 ? () => {} : props.setColor(c)
        }
        placeholder={props?.placeholder || '#FF00FF (Your hex color)'}
        value={props.color}
        style={{
          width: 250
        }}
      ></PUTextInput>
    </View>
  );
};
