import React from 'react';
import { CartsList } from '../components/CartsList';

export const CartsScreen = (props: any) => {
  return (
    <>
      <CartsList></CartsList>
    </>
  );
};
